import React from 'react'
import Helmet from 'react-helmet'
import MainLayout from '../../../components/mainlayout'
import { navigate } from 'gatsby'
import { Row, Col, Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import EditOrder from '../../../components/order/edit'
import 'bootstrap/scss/bootstrap.scss'

export default function EditOrderPage(props) {
  return (
    <MainLayout>
      <Helmet title="Илгээмж засах | SB Express" defer={false} />
      <Row>
        <Col className="text-center w-100 mb-4">
          <div className="sb-arrow-back">
            <Button
              type="link"
              className="sb-arrow-back"
              onClick={() => {
                navigate(`/order/${props.id}`)
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} size="xs" />
              <span>Буцах</span>
            </Button>
          </div>
          <span className="text-uppercase order-details-title">Илгээмж засах</span>
        </Col>
      </Row>
      <EditOrder orderId={props.id} />
    </MainLayout>
  )
}
